export enum Stage {
  Q = 'Q-Stage',
  INT = 'INT-Stage',
  PROD = 'PROD-Stage'
}

export function getStage(url: string): Stage {
  // Always use Q-Stage for Admin
  if (url === '/admin' || url.startsWith('/Q')) {
    return Stage.Q;
  }
  if (url.startsWith('/INT')) {
    return Stage.INT;
  }
  return Stage.PROD;
}

export function getURLStage(stage: Stage): string {
  switch (stage) {
    case Stage.PROD:
      return '';
    case Stage.INT:
      return 'INT';
    case Stage.Q:
      return 'Q';
  }
}
