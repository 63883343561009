import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Observable, PartialObserver } from 'rxjs';

@Injectable()
// eslint:disable-next-line: component-class-suffix
export abstract class SubscriptionHelper implements OnDestroy {
  private subSink = new Subscription();

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  protected subscribe<T>(
    observable: Observable<T>,
    observerOrNext?: PartialObserver<T> | ((value: T) => void),
    error?: (error: any) => void,
    complete?: () => void
  ): Subscription {
    return this.subSink.add(
      observable.subscribe({
        next: observerOrNext as any,
        error,
        complete
      })
    );
  }

  protected unsubscribe(innerSub: Subscription) {
    this.subSink.remove(innerSub);
  }
}
