import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { sp } from '@pnp/sp';
import '@pnp/sp/webs';
import '@pnp/sp/files';
import { environment } from 'src/environments/environment';

export class TranslateSpoLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    const baseUrl = location.origin + environment.spoSite + '/';
    return from(
      sp
        .configure({}, baseUrl)
        .web.getFileByServerRelativePath(
          `${environment.spoSite}/${environment.folder}/${lang}.json`
        )
        .getJSON()
    );
  }
}
