export type SiteContentOption = Page | StaticContent | Settings;
export type SiteStructure = SiteContentOption[];

/**
 * General site content
 */
export enum SiteContentType {
  Page = 'page',
  Static = 'staticContent',
  Settings = 'settings'
}
export interface SiteContent {
  type: SiteContentType;
}

/**
 * PAGE
 */
export enum PageContentType {
  ImageSlider = 'image-slider',
  Quote = 'quote',
  PictureContainer = 'picture-container',
  Compilation = 'compilation',
  Numbers = 'numbers',
  Spacer = 'spacer',
  TextBlock = 'text-block',
  TextImageLinkImage = 'text-image-link-image',
  LinkList = 'link-list'
}
export interface PageContent {
  type: PageContentType;
  [key: string]: any; // FIXME add type
}
export interface Page extends SiteContent {
  type: SiteContentType.Page;
  content: PageContent[];
  showInHeader: boolean;
  pageName: string;
}

/**
 * STATIC
 */
export interface Footer {
  button: { text: string; url: string };
  header: string;
  links: {
    category: string;
    linksArray: { title: string; url: string }[];
  }[];
  socialLinks: { icon: string; url: string; title: string }[];
  text: string;
}
export interface RelatedContent {
  headline: string;
  img: string;
  text: string;
}
export interface StaticContent extends SiteContent {
  type: SiteContentType.Static;
  content: {
    footer: Footer;
    'related-content': RelatedContent[];
  };
}

/**
 * SETTINGS
 */
export interface Settings extends SiteContent {
  type: SiteContentType.Settings;
  languages: string[];
}
