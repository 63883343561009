import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gmm-toggle',
  templateUrl: './gmm-toggle.component.html',
  styleUrls: ['./gmm-toggle.component.scss']
})
export class GmmToggleComponent {


  @Input() model = '';
  @Input() label = '';
  @Input() name = '';
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() disabled = false;
  @Output() modelChange = new EventEmitter();

}
