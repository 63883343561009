import { Injectable } from '@angular/core';
import { BackendService, User } from '@shared/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;

  constructor(private backend: BackendService) {}

  async load(forceUpdate = false): Promise<void> {
    if (!forceUpdate && this.user) {
      return;
    }
    this.user = await this.backend.getUser();
  }

  isAdmin(): boolean {
    if (this.user?.isAdmin) {
      return true;
    }
    return false;
  }
}
