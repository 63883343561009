import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGMMSelectOption } from './IGMMSelectOption';

@Component({
  selector: 'gmm-select',
  templateUrl: './gmm-select.component.html',
  styleUrls: ['./gmm-select.component.scss']
})
export class GmmSelectComponent {

  @Input() model: IGMMSelectOption<any>;

  @Input() label = '';
  @Input() optionList: IGMMSelectOption<any>[];
  @Input() name: string;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() required = true;
  @Output() modelChange = new EventEmitter();

  compareItems(i1: IGMMSelectOption<any>, i2: IGMMSelectOption<any>): boolean {
    return i1 && i2 && i1.label === i2.label;
  }
}
