import { Page } from '@admin/models/site-structure.model';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ContentDataService } from '../../general-content-create/services/content.data';
import { UserService } from './user.service';

/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class NavItemsService {
  @Output() navChange: EventEmitter<any> = new EventEmitter();
  pages: Page[] = [];

  constructor(
    private contentData: ContentDataService,
    private userService: UserService
  ) {
    this.init();
  }

  async init() {
    // TODO await login
    await this.userService.load();
    this.contentData.pages.subscribe((p) => {
      this.pages = p;
      this.navChange.emit();
    });
  }

  getNavItems() {
    const links = [];

    for (const itm of this.pages) {
      if (itm.showInHeader) {
        links.push({
          CssClass: 'key',
          Href: '/' + (itm.pageName === 'home' ? '' : itm.pageName),
          Name: itm.pageName,
          HasNotifications: false,
          NotificationValue: 0
        });
      }
    }
    if (this.userService.isAdmin()) {
      links.push({
        CssClass: 'admin',
        Href: '/admin',
        Name: 'admin',
        HasNotifications: false,
        NotificationValue: 0
      });
      // links.push({ CssClass: '', Href: '/form', Name: 'form', HasNotifications: false, NotificationValue: 0 }); // is this component even needed? doesn't do anything...
    }
    return links;
  }

  navItemsUpdated() {
    this.navChange.emit(true);
  }

  // TODO sharepoint get
  getSideMenuNavItems() {
    const sideMenuEntries = [
      {
        CssClass: 'key',
        Href: '/side-page3',
        Name: 'sidePage3',
        ParentName: 'form'
      },
      {
        CssClass: 'key',
        Href: '/side-page',
        Name: 'sidePage',
        ParentName: 'admin'
      },
      {
        CssClass: 'key',
        Href: '/side-page2',
        Name: 'sidePage2',
        ParentName: 'admin'
      }
    ];

    return sideMenuEntries;
  }
}
