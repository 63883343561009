import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public open;
  @Output() change: EventEmitter<any[]> = new EventEmitter();

  toggle(emitItems) {
    this.open = emitItems;
    this.change.emit(this.open);
  }
}
