<div class="m-form" style="padding: 20px;">
  <form>
    <!-- <div class="m-select-wrapper">
      <div class="input-field">
        <label for="language">{{'languageSelection' | translate}}</label>
      </div>
    </div> -->

    <!-- <gmm-default-settings [data]="data"></gmm-default-settings> -->

    <!-- <mat-form-field style="width: 100%;">
      <mat-select [placeholder]="'pleaseSelect' | translate" [(value)]="languageSelected" (selectionChange)="changeLanguage($event)">
        <mat-option *ngFor="let language of languages" [value]="language">{{language | translate}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- <select name="language" (change)="changeLanguage($event)" [(ngModel)]="languageSelected" materialize="material_select" class="form-control">
      <option disabled=true value="">{{'pleaseSelect' | translate}}</option>
      <option *ngFor='let language of languages' [ngValue]='language'>{{language | translate}}</option>
    </select> -->
  </form>
</div>
