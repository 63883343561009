import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gmm-input',
  templateUrl: './gmm-input.component.html',
  styleUrls: ['./gmm-input.component.scss']
})
export class GmmInputComponent {

  @Input() model = '';
  @Input() name: string;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() required = false;
  @Output() modelChange = new EventEmitter();
}

