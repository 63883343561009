import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';

import { sp } from '@pnp/sp';
import '@pnp/sp/webs';
import '@pnp/sp/files';
import '@pnp/sp/folders';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { getStage } from '@shared/helpers/staging';

export interface FileLocation {
  folder: string;
  stage: string;
  file: string;
}

export interface User {
  isAdmin: boolean;
}

interface Provider {
  user(): Promise<User>;

  get<T = any>(loc: string): Observable<T[]>;

  put(stage: string, file: string, data: any): Observable<any>;
}

export class AWSProvider implements Provider {
  private http: HttpClient;
  private apiUrl: string;
  private assetsUrl: string;

  constructor(http: HttpClient, apiUrl: string, assetsUrl: string) {
    this.http = http;
    this.apiUrl = apiUrl;
    this.assetsUrl = assetsUrl;
  }

  user(): Promise<User> {
    return this.http
      .post<User>(`${this.apiUrl}/user`, { folder: environment.project })
      .toPromise();
  }

  get(loc: string) {
    return from(
      (async () => {
        const [stage, file] = loc.split('/');
        const req = { folder: environment.project, stage, file };
        const { link } = await this.http
          .post<{ link: string }>(`${this.apiUrl}/download`, req)
          .toPromise();
        return this.http.get<any[]>(link).toPromise();
      })()
    );
  }

  put(stage: string, file: string, data: any) {
    return from(
      new Promise<void>((resolve, reject) => {
        const req = { folder: environment.project, stage, file };
        this.http
          .post(this.apiUrl + '/upload', JSON.stringify(req))
          .subscribe((linkItem) => {
            this.http
              .put(linkItem['link'], JSON.stringify(data), {
                responseType: 'text'
              })
              .subscribe(
                () => {
                  resolve();
                },
                () => {
                  reject();
                }
              );
          });
      })
    );
  }

  invalidate(stage: string) {
    const req = { folder: environment.project, stage };
    return this.http.post(this.apiUrl + '/invalidation', JSON.stringify(req));
  }
}

export class SPOProvider implements Provider {
  constructor() {
    sp.setup({
      sp: {
        baseUrl: location.origin + environment.spoSite + '/'
      }
      // spfxContext: this.context
    });
  }

  user(): Promise<User> {
    throw new Error('User is not implemented yet for SPO provider!');
  }

  get(loc: string) {
    return from(
      sp.web
        .getFileByServerRelativePath(
          `${environment.spoSite}/${environment.folder}/${loc}`
        )
        .getJSON()
    );
  }

  put(stage: string, file: string, data: any) {
    return from(
      sp.web
        .getFolderByServerRelativeUrl(
          `${environment.spoSite}/${environment.folder}/${stage}`
        )
        .files.add(file, JSON.stringify(data), true)
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  provider: Provider;
  private maxRetry = 5;

  constructor(private router: Router) {}

  use(provider: Provider): void {
    this.provider = provider;
  }

  async getUser(retry: number = 0): Promise<any> {
    try {
      return await this.provider.user();
    } catch (e) {
      if (retry > this.maxRetry) {
        throw e;
      }
      await this.timeout(300);
      return this.getUser(retry + 1);
    }
  }

  timeout(timeout: number) {
    return new Promise((resolve) => setTimeout(() => resolve(null), timeout));
  }

  get(loc: string, stage: string = this.getCurrentStage()) {
    return this.provider.get(`${stage}/${loc}`);
  }

  put(stage: string, file: string, data: any) {
    return this.provider.put(stage, file, data);
  }

  getCurrentStage() {
    return getStage(this.router.url);
  }
}
