import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'gmm-checkbox',
  templateUrl: './gmm-checkbox.component.html',
  styleUrls: ['./gmm-checkbox.component.scss']
})
export class GmmCheckboxComponent {

  @Input() data;
  @Input() model = '';
  @Input() disabled = false;
  @Input() label = '';
  @Input() required = false;

  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  dataChange(e) {
    this.data[this.model] = e;
    this.modelChange.emit(e);
  }
}
