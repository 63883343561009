<mat-form-field appearance="fill" class="gmm-form-field">
    <mat-label>{{label | translate}}</mat-label>
    <input
        matInput
        [attr.aria-label]="name"
        [name]="name"
        [type]="type"
        [(ngModel)]="model"
        placeholder="{{placeholder | translate}}"
        [disabled]="disabled"
        (ngModelChange)="modelChange.emit(model)"
        [required]="required">
        <mat-error>{{'error.message' | translate}}</mat-error>
</mat-form-field>