import {
  Page,
  PageContent,
  Settings,
  SiteContentOption,
  SiteContentType,
  SiteStructure,
  StaticContent
} from '@admin/models/site-structure.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getStage, Stage } from '@shared/helpers/staging';
import { ContentTranslateService } from '@shared/services/content-translate.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from 'src/app/shared/services/backend.service';

// export let ContentData = [];
// export let StaticContentData = {};
// export let SettingsData = new SettingsDataModel();

// TODO: bugfix/architecture circular dependency problems when providing in module

@Injectable({
  providedIn: 'root'
})
export class ContentDataService {
  structure: SiteStructure;
  stage: Stage;

  pages = new BehaviorSubject<Page[]>([]);
  settings = new BehaviorSubject<Settings>(null);
  staticContent = new BehaviorSubject<StaticContent>(null);

  loading: Promise<void>;

  selectedStage: Stage;

  constructor(
    private router: Router,
    private backend: BackendService,
    private translate: ContentTranslateService
  ) {}

  page(): Observable<PageContent[]> {
    let page = this.getPage();
    if (page.startsWith('/')) {
      page = page.substring(1);
    }
    return this.pages.pipe(
      map((pages) => {
        if (page === '') {
          return pages.find((itm) => itm.pageName === 'home')?.content;
        } else {
          const pageContent = pages.find((itm) => itm.pageName === page);
          if (pageContent) {
            return pageContent.content;
          } else {
            return [];
          }
        }
      })
    );
  }

  async loadStructure(url: string): Promise<void> {
    // try {
    const stage = getStage(url);
    if (this.stage !== stage) {
      // Load new translation file
      this.translate.use(this.translate.languageSelected, stage);

      // Load new structure file
      this.loading = this._load(stage);
      return await this.loading;
    }
    // } catch (err) {
    //   console.log('failed to load data', err);
    // }
  }

  async _load(stage: Stage): Promise<void> {
    this.structure = await this.backend
      .get('site-structure.json', stage)
      .toPromise();

    this.pages.next(
      this.structure.filter<Page>(is<Page>(SiteContentType.Page))
    );
    this.settings.next(
      this.structure.find<Settings>(is<Settings>(SiteContentType.Settings))
    );
    this.staticContent.next(
      this.structure.find<StaticContent>(
        is<StaticContent>(SiteContentType.Static)
      )
    );

    this.stage = stage;
  }

  getPage(url = this.router.url): string {
    if (url.startsWith('/Q')) {
      return url.replace('/Q', '');
    }
    if (url.startsWith('/INT')) {
      return url.replace('/INT', '');
    }
    return url;
  }
}

function is<T extends SiteContentOption>(
  type: SiteContentType
): (el: SiteContentOption) => el is T {
  return function (el: SiteContentOption) {
    return el.type === type;
  } as any;
}
