import { trigger, transition, style, animate } from '@angular/animations';

export const shrinkExpandAnimation = trigger('shrinkExpand', [
  transition(':enter', [
    style({
      height: '0px',
      minHeight: '0px',
      maxHeight: '0px',
      padding: '0',
      margin: '0',
      opacity: 0,
      overflow: 'hidden'
    }),
    animate(
      '550ms ease-in-out',
      style({
        height: '*',
        minHeight: '*',
        maxHeight: '*',
        padding: '*',
        margin: '*',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({
      height: '*',
      minHeight: '*',
      maxHeight: '*',
      padding: '*',
      margin: '*',
      opacity: 1
    }),
    animate(
      '500ms ease-in-out',
      style({
        height: '0px',
        minHeight: '0px',
        maxHeight: '0px',
        padding: '0',
        margin: '0',
        opacity: 0,
        overflow: 'hidden'
      })
    )
  ])
]);
