<mat-form-field appearance="fill" class="gmm-form-field">
    <mat-label>{{label | translate}}</mat-label>
    <mat-select
        [name]="name"
        placeholder="{{placeholder | translate}}"
        [compareWith]="compareItems"
        [disabled]="disabled"
        [required]="required"
        [(ngModel)]="model"
        (ngModelChange)="modelChange.emit(model)"
        panelClass="gmm-select">
        <mat-option *ngFor="let item of optionList" [value]="item">
            {{item.label | translate}}
        </mat-option>
    </mat-select>
    <mat-error>{{'error.message' | translate}}</mat-error>
</mat-form-field>