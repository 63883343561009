import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gmm-action-button',
  templateUrl: './gmm-action-button.component.html',
  styleUrls: ['./gmm-action-button.component.scss']
})
export class GmmActionButtonComponent {
  
  @Input() buttonText = '';
  @Input() icon = '';
  @Input() size = '35px';
  @Input() color: 'primary' | 'accent' = 'primary';
  @Output() buttonClicked = new EventEmitter();

  onClick() {
    this.buttonClicked.emit();
  }
}
