import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gmm-menu-subcomponents',
  templateUrl: './menu-subcomponents.component.html',
  styleUrls: ['./menu-subcomponents.component.scss']
})
export class MenuSubcomponentsComponent implements OnInit {
  languageSelected = 'en';
  languages = ['en'];

  data = {
    language: 'en',
    country: 'Germany',
    currency: 'EUR'
  };

  constructor() {}

  ngOnInit() {
    const userSettings = localStorage.getItem('userSettings');
    if (userSettings !== null) {
      this.data = JSON.parse(userSettings);
    }
  }

  // changeLanguage(e) {
  //   this.languageSelected = e.value;
  //   localStorage.setItem('language', this.languageSelected);
  //   window.location.reload();
  // }
}
