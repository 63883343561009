<label class="gmm-label">{{label | translate}}</label>
<mat-radio-group
    aria-label="Select an option"
    class="gmm-radio-group"
    [(ngModel)]="selected"
    (ngModelChange)="modelChange.emit(selected)"
    [class.horizontal]="isHorizontal"
    [disabled]="disabled">
    <mat-radio-button
        *ngFor="let option of options"
        [value]="option"
        [disableRipple]="true"
        [disabled]="option.disabled">
        {{option.label | translate}}
    </mat-radio-button>
</mat-radio-group>