import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private oauthService: OAuthService) {}

  login(): Promise<any> {
    this.oauthService.configure(environment.authentication);
    return this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  logout(): void {
    this.oauthService.logOut();
  }
}
