import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { NavItemsService } from '../services/nav-items.service';

@Component({
  selector: 'gmm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menuLanguage = false;
  menuMenu = false;
  layerHeight;
  closeState = true;
  navItems: any;

  constructor(private menuService: MenuService, private navItemsService: NavItemsService) {
    this.navItems = navItemsService.getNavItems();
  }

  ngOnInit() {
    this.menuService.change.subscribe((isOpen) => {
      /*this.menuLanguage = false;
            this.menuMenu = false;

            switch (isOpen) {
              case 'Language':
                this.menuLanguage = true;
                break;
              case 'Menu':
                this.menuMenu = true;
                break;
            }
            document.querySelector('.js-menu').classList.add('show');*/
    });
  }

  closeMenu() {
    document.querySelector('.js-menu').classList.remove('show');
    document.querySelector('body').classList.remove('noscroll');
    this.closeState = true;
  }
}
