<div class="mod-header" *ngIf="settingsData">
  <div class="row">
    <div class="mod-logo">
      <div class="m-logo m-logo--siemens js-logo js-logo-siemens">
        <a [routerLink]="['/']"></a>
      </div>
    </div>

    <div class="mod-navigation mod-navigation--icons">
      <div *ngIf="user.isAdmin()" class="m-icon m-icon--settings" tooltip="view different stage" placement="bottom">
        <p class="stage-indicator" mat-button [matMenuTriggerFor]="stageMenu">{{ contentData.selectedStage }}</p>
        <mat-menu #stageMenu="matMenu">
          <button mat-menu-item *ngFor="let s of stages" (click)="changeStage(s.stage)">{{ s.title | translate }}</button>
        </mat-menu>
      </div>

      <div class="m-icon m-icon--settings" tooltip="toggle theme" placement="bottom">
        <i class="icon icon--burger navigation-menu" mat-button [matMenuTriggerFor]="navMenu"></i>
        <mat-menu #navMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let navItem of navItems; let i = index"
            (click)="openTab({index: i})"
            [class.active]="i === selectedTab"
          >
            {{'pages.' + navItem.Name | translate}}
          </button>
        </mat-menu>

        <i class="icon icon--settings" mat-button [matMenuTriggerFor]="mainMenu"></i>
        <mat-menu #mainMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="languages">Language</button>
          <button mat-menu-item [matMenuTriggerFor]="theme">Theme</button>
        </mat-menu>
        <mat-menu #languages="matMenu">
          <button *ngFor="let lang of settingsData.languages" mat-menu-item (click)="changeLanguage(lang)" style="display: inline-flex">
            {{'general.' + lang | translate}}<i
              class="frontIcon icon icon--check"
              style="margin-left: auto; margin-top: auto; margin-bottom: auto"
              *ngIf="languageSelected === lang"
            ></i>
          </button>
        </mat-menu>
        <mat-menu #theme="matMenu">
          <button mat-menu-item (click)="toggleTheme('light')" style="display: inline-flex">
            {{'general.light' | translate}}<i
              class="frontIcon icon icon--check"
              style="margin-left: auto; margin-top: auto; margin-bottom: auto"
              *ngIf="!darkMode"
            ></i>
          </button>
          <button mat-menu-item (click)="toggleTheme('dark')" style="display: inline-flex">
            {{'general.dark' | translate}}<i
              class="frontIcon icon icon--check"
              style="margin-left: auto; margin-top: auto; margin-bottom: auto"
              *ngIf="darkMode"
            ></i>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div style="position: absolute; top: 65px">
    <mat-tab-group class="nav-part" disableRipple [selectedIndex]="selectedTab" (selectedTabChange)="openTab($event)">
      <mat-tab *ngFor="let navItem of navItems; let i = index">
        <ng-template mat-tab-label>
          <ng-container *ngIf="!hasSideMenu(navItem); else sideMenu">
            <p>{{'pages.' + navItem.Name | translate}}</p>
          </ng-container>
          <ng-template #sideMenu>
            <div
              mat-icon-button
              [matMenuTriggerFor]="Menu"
              class="cloneIcon"
              #menuTrigger="matMenuTrigger"
              (mouseenter)="mouseEnter(menuTrigger, i)"
              (mouseleave)="mouseLeave(menuTrigger, i)"
            >
              <p>{{'pages.' + navItem.Name | translate}}</p>
            </div>
            <mat-menu #Menu="matMenu" [hasBackdrop]="false">
              <div (mouseover)="mouseEnter(menuTrigger, i)" (mouseout)="mouseLeave(menuTrigger, i)">
                <ng-container *ngFor="let sideMenuEntry of sideMenuMap[navItem.Name]">
                  <button mat-menu-item style="background-color: white" (click)="navigateTo(sideMenuEntry)">
                    {{'pages.' + sideMenuEntry['Name'] | translate}}
                  </button>
                </ng-container>
              </div>
            </mat-menu>
          </ng-template>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
