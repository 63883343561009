import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { IGMMRadioOption } from './IGMMRadioOption';

@Component({
  selector: 'gmm-radio-button',
  templateUrl: './gmm-radio-button.component.html',
  styleUrls: ['./gmm-radio-button.component.scss']
})
export class GmmRadioButtonComponent implements OnInit {

  @Input() isHorizontal = false;
  @Input() model: IGMMRadioOption<any>;
  @Input() options: IGMMRadioOption<any>[];
  @Input() label = '';
  @Input() disabled = false;

  selected: IGMMRadioOption<any>;

  @Output() modelChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.selected = this.options.filter(s => s.label === this.model.label)[0];
  }

}
