<div class="mod-footer">
  <div class="m-info">
    <span>gms-cms.siemens.cloud</span>
    <span>© Siemens AG {{year}}</span>
  </div>
  <div class="m-footer-nav">
    <ul>
      <li><a href="https://www.siemens.com/corp/en/index/corporate_info.htm" target="_blank">{{'corporateInfo' | translate}}</a></li>
      <li><a href="https://www.siemens.com/corp/en/index/privacy.htm" target="_blank">{{'privacyPolicy' | translate}}</a></li>
      <li><a href="https://www.siemens.com/corp/en/index/siemens_ag_cookie_policy.htm" target="_blank">{{'cookiePolicy' | translate}}</a></li>
      <li><a href="https://www.siemens.com/corp/en/index/terms_of_use.htm" target="_blank">{{'termsOfUse' | translate}}</a></li>
      <li><a href="https://www.siemens.com/corp/en/index/digital_id.htm" target="_blank">{{'digitalId' | translate}}</a></li>
    </ul>
  </div>
</div>
