import { EventEmitter, Injectable, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Stage } from '@shared/helpers/staging';
import { BackendService } from './backend.service';

/**
 * This service is a wrapper for the native ngx-translate service so that regular ngx loaders with all their benefits can be used.
 *
 * It sets the ngx-translate language in the format {stage}/{lang} based on the current stage and language.
 */
@Injectable({
  providedIn: 'root'
})
export class ContentTranslateService {
  defaultLang: string;

  languageSelected: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLangChange = new EventEmitter<LangChangeEvent>();

  constructor(
    private backend: BackendService,
    private translate: TranslateService
  ) {}

  /**
   * Changes the lang currently used
   */
  use(lang: string, stage = this.backend.getCurrentStage()): void {
    this.languageSelected = lang;
    this.onLangChange.emit({ lang, translations: [] });
    this.loadLanguage(stage);
  }

  setDefaultLang(lang: string): void {
    this.defaultLang = lang;
    if (!this.languageSelected) {
      this.languageSelected = lang;
    }
  }

  private loadLanguage(stage: Stage) {
    this.translate.use(`${stage}/${this.languageSelected}`);
  }
}
