import { AUTH_CLIENT_ID, CMS_PROJECT } from './cms-project';

export const environment = {
  production: true,
  isSharepoint: false,
  project: CMS_PROJECT, // Which project's images should be accessed?

  // AWS version
  apiUrl: 'https://h45syo6nk3.execute-api.eu-central-1.amazonaws.com/dev',
  resourceBucket: 'https://d1nx9hpodl5uv8.cloudfront.net/',
  authentication: {
    issuer:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM',
    redirectUri: window.location.origin + '/auth/callback',
    clientId: AUTH_CLIENT_ID,
    dummyClientSecret: null,
    responseType: 'code',
    scope: 'openid profile email',
    showDebugInformation: true,
    strictDiscoveryDocumentValidation: false
  },

  // SPO version
  spoSite: '',
  folder: ''
};
