export class TranslationHelper {
  public static updateTranslation(translationFile: Record<string, unknown>, change: string, text: string): void {
    const textSplit = text.split('.');
    this.updateTransFileRecursive(translationFile, textSplit, change, 0);
  }

  private static updateTransFileRecursive(transfile, langPath, change, i) {
    if (i < langPath.length - 1) {
      if (!transfile[langPath[i]]) {
        transfile[langPath[i]] = {};
      }
      this.updateTransFileRecursive(transfile[langPath[i]], langPath, change, i + 1);
    } else {
      transfile[langPath[i]] = change;
    }
  }
}
