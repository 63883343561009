import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import '@pnp/sp/lists';
import '@pnp/sp/webs';
import {
  AWSProvider,
  BackendService,
  SPOProvider
} from '@shared/services/backend.service';
import { ContentTranslateService } from '@shared/services/content-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'GMM';
  loadingComplete = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private translate: ContentTranslateService,
    private backend: BackendService
  ) {
    if (environment.isSharepoint) {
      const spoProvider = new SPOProvider();
      this.backend.use(spoProvider);
    } else {
      const apiUrl = environment.apiUrl;
      const assetsUrl = environment.resourceBucket + environment.project;
      const awsProvider = new AWSProvider(this.http, apiUrl, assetsUrl);
      this.backend.use(awsProvider);
    }
    this.translate.setDefaultLang('en');
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      window.scrollTo(0, 0);
    });
  }
}
