import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { ContentDataService } from '@general-content-create/services/content.data';
import { getStage, getURLStage, Stage } from '@shared/helpers/staging';
import { UserService } from '@static/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ContentDataGuard implements CanActivate {
  constructor(private contentData: ContentDataService, private router: Router, private dialog: MatDialog, private user: UserService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.contentData.selectedStage) {
      // initial load, set selected stage
      this.contentData.selectedStage = getStage(state.url);
    }
    // always make sure to load sites of the selected stage
    if (state.url !== '/admin' && this.contentData.selectedStage !== getStage(state.url)) {
      const url = getURLStage(this.contentData.selectedStage) + this.contentData.getPage(state.url);
      return this.router.createUrlTree([ url ]);
    }
    try {
      await this.contentData.loadStructure(state.url);
      if (state.url === '/admin' && !this.user.isAdmin()) {
        return false;
      }
      return true;
    } catch (err) {
      if (err.status === 404) {
        this.dialog.open(ComingSoonComponent, { minWidth: '100vw', height: '100vh', disableClose: true });
        return true;
      }
      // redirect to 404 page
      return this.router.createUrlTree(['not-found']);
    }
  }
}

@Component({
  template: `
  <div style="height: 100%; display: flex; align-items: center; justify-content: center">
    <h1>Coming soon</h1>
    <a *ngIf="user.isAdmin()" style="position: absolute; bottom: 5%" (click)="dialogRef.close()" routerLink="/admin">Admin</a>
  </div>`
})
export class ComingSoonComponent {
  constructor(
    public dialogRef: MatDialogRef<ComingSoonComponent>,
    public user: UserService
  ) {}
}
