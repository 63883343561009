import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'instantTranslate', pure: false })
export class InstantTranslationPipe implements PipeTransform {
  transform(text: string, translationFile: Record<string, unknown>): string {
    if (!text || !translationFile) {
      return text;
    }

    const textSplit = text.split('.');
    let transHolder = JSON.parse(JSON.stringify(translationFile));
    for (const itm of textSplit) {
      if (!transHolder[itm]) {
        return text;
      }

      transHolder = transHolder[itm] ? transHolder[itm] : text;
    }

    return transHolder;
  }
}
